import React, { useContext, useEffect, useMemo } from 'react';
import * as styles from './styles.module.sass';

import { BounceLoader } from 'react-spinners';
import { connect } from "react-redux";
import { loginSuccess } from "../../redux-store/actions/login-actions";
import { hot } from "react-hot-loader";
import StoreData from "../../contexts/StoreData";
import { useGetStoreData } from "../../hooks/login-hook";
import { Location } from "history";
import { Redirect } from "react-router";
import { useRecoilState } from "recoil";
import { remoteAuthenticationAtom } from "../../atoms/remote-authentication.atom";

interface IProps {
  location: Location;
  loginSuccess: (data) => void;
}

const Login: React.FC<IProps> = props => {
  const [, setRemoteAuthenticationState] = useRecoilState(
    remoteAuthenticationAtom
  );
  useEffect(() => {
    console.log("fired from login page");
  })
  const parsedToken = useMemo(() => {
    const params = new URLSearchParams(props.location.search);
    return params.get("token") as string;
  }, []);
  const parsedIsRemoteAuthentication = React.useMemo(() => {
    const params = new URLSearchParams(props.location.search);
    return params.get("is_remote_authentication") as string;
  }, []);
  const parsedStoreId = React.useMemo(() => {
    const params = new URLSearchParams(props.location.search);
    return params.get("store_id") as string;
  }, []);
  useEffect(() => {
    if (parsedToken) {
      localStorage.setItem("token", parsedToken);
    }
  }, [parsedToken]);
  useEffect(() => {
    if (parsedStoreId) {
      localStorage.setItem("store_id", parsedStoreId);
    }
  }, [parsedStoreId]);
  useEffect(() => {
    if (parsedIsRemoteAuthentication) {
      localStorage.setItem("is_remote_authentication", String(true));
      setRemoteAuthenticationState(true);
    }
  }, [parsedIsRemoteAuthentication]);
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("is_remote_authentication") as any)) {
      setRemoteAuthenticationState(true);
    }
  }, [JSON.parse(localStorage.getItem("is_remote_authentication") as any)]);
  useGetStoreData(parsedToken);
  const { storeData, isLoggedIn, token } = useContext(StoreData);
  useEffect(() => {
    if (storeData && isLoggedIn && token) {
      props.loginSuccess({ ...storeData, token });
    }
  }, [isLoggedIn]);

  const { from } = props.location.state as { [key: string]: any } || { from: { pathname: "/" } };
  if (isLoggedIn) {
    return <Redirect to={from} />;
  }

  return (
    <div className={styles.wrapper}>
      <BounceLoader
        className={styles.override}
        sizeUnit={"px"}
        size={100}
        color={"#FEBE19"}
        loading={true}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  loginSuccess: data => dispatch(loginSuccess(data)),
});

export default hot(module)(connect(null, mapDispatchToProps)(Login) as any);
